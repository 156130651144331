<template>
  <div class="regist-main">
    <h2 class="brand-info__text">社区健康平台</h2>
    <el-alert title="恭喜你注册成功" type="success" :hidden='isHiddenSuccess'> </el-alert>
    <el-alert :title='message' type="error" :hidden='isHiddenError' :closable='isClose'> </el-alert>
    <div class="text-home">
      <img
          style="zoom:0.06"
          src="../../../public/img/logo.png"
      />心狗健康
    </div>
    <h3 class="regist-title">欢迎注册</h3>
    <el-form
        :model="dataForm"
        :rules="dataRule"
        ref="dataForm"
        @keyup.enter.native="dataFormSubmit()"
        status-icon
    >
      <el-form-item prop="userName">
        <el-input
            v-model="dataForm.userName"
            placeholder="用户名"
        ></el-input>
      </el-form-item>

      <el-form-item prop="phoneNumber">
        <el-input
            v-model="dataForm.phoneNumber"
            placeholder="手机号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
            v-model="dataForm.password"
            type="password"
            placeholder="密码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="confirmPassword">
        <el-input
            v-model="dataForm.confirmPassword"
            type="Password"
            placeholder="确认密码"
        ></el-input>
      </el-form-item>

      <v-distpicker
          @province="onChangeProvince"
          @city="onChangeCity"
          @area="onChangeArea"
      ></v-distpicker>
      <br/>
      <el-form-item prop="inviteCode">
        <el-input
            v-model="dataForm.inviteCode"
            placeholder="邀请码"
        ></el-input>
      </el-form-item>
      <!--
              <el-form-item prop="radio">
                  <el-radio-group v-model="dataForm.radio">
                  <el-radio :label="'regist'">政府监管</el-radio>
                      <el-radio :label="'doctor'">签约医生</el-radio><br />
                  </el-radio-group>
              </el-form-item> -->
      <el-form-item>
        <el-button
            class="regist-btn-submit"
            type="primary"
            @click="dataFormSubmit()"
        >注册</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button
            class="regist-btn-submit"
            type="primary"
            @click="gotoLogin()"
        >已有帐号？马上登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import VDistpicker from "v-distpicker";
export default {
  components: { VDistpicker },
  data() {
    var validateConfirmPassword = (rule, value, callback) => {
      if (this.dataForm.password !== value) {
        callback(new Error("确认密码与密码不一致"));
      } else {
        callback();
      }
    };
    return {
      isHiddenSuccess: true,
      isHiddenError: true,
      isClose: false,
      message: '',
      dataForm: {
        userName: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        province: "",
        city: "",
        area: "",
        region: "",
        inviteCode: "",
        radio: "regist",
      },
      dataRule: {
        userName: [
          { required: true, message: "帐号不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { validator: validateConfirmPassword, trigger: "blur" },
        ],
        captcha: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],
        inviteCode: [
          { required: true, message: "邀请码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.isHiddenSuccess = true;
    this.isHiddenError = true;
  },
  methods: {
    onChangeProvince(province) {
      this.dataForm.province = province.value;
    },
    onChangeCity(city) {
      this.dataForm.city = city.value;
    },
    onChangeArea(area) {
      this.dataForm.area = area.value;
    },

    // 提交表单
    dataFormSubmit() {
      if (this.dataForm.area != "") {
        // 只取所选地区的最后一级来送到后端
        this.dataForm.region = this.dataForm.area;
      } else if (this.dataForm.city != "") {
        this.dataForm.region = this.dataForm.city;
      } else if (this.dataForm.province != "") {
        this.dataForm.region = this.dataForm.province;
      }
      // console.log(this.dataForm)
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          var path = "/sys/" + this.dataForm.radio; // 根据登录角色来提交表单地址
          this.$http({
            url: this.$http.adornUrl(path),
            method: "post",
            data: this.$http.adornData({
              userName: this.dataForm.userName,
              phoneNumber: this.dataForm.phoneNumber,
              email: this.dataForm.email,
              password: this.dataForm.password,
              region: this.dataForm.region,
              inviteCode: this.dataForm.inviteCode,
            }),
          }).then(({ data }) => {
            if (data.code === 0) {
              console.log("注册成功");
              this.isHiddenSuccess = false
              setTimeout(()=>{this.isShowSuccess = true},2000)
              this.$router.replace({ name: 'login' })
            } else {
              console.log("注册失败");
              console.log(data)
              this.message = data.msg
              this.isHiddenError = false
            }
          });
        }
      });
    },
    // 转到登录界面
    gotoLogin() {
      this.$router.replace({ name: "login" });
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss">
.brand-info__text {
  text-align: center;
  margin: 0 0 22px 0;
  font-size: 34px;
  font-weight: 500px;
  text-transform: uppercase;
};
.regist-main {
  position: absolute;
  top: 50%;
  left: 50%;
  // padding: 150px 60px 180px;
  width: 550px;
  transform: translate(-50%, -50%);
  // min-height: 100%;
  // background-color: #fff;
}
.regist-title {
  font-size: 24px;
}
.regist-captcha {
  overflow: hidden;
  > img {
    width: 100%;
    cursor: pointer;
  }
}
.login-btn-submit {
  width: 100%;
  margin-top: 25px;
}
.regist-btn-submit {
  width: 100%;
  // margin-top: 8px;
}
.text-home {
  text-align: center;
  font-size: 30px;
  color: red;
  margin-bottom: 10px;
}
</style>
