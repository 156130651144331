<template>
  <div class="regist-main">
    <div class="text-home">
      <img style="zoom:0.06" src="../../../src/assets/img/logo.png" />
    </div>
    <h1 class="brand-info__text">我的健康——个人平台</h1>
    <!-- <el-alert title="恭喜你注册成功" type="success" :hidden="isHiddenSuccess">
    </el-alert> -->
    <!-- <el-alert :title="message" type="error" :hidden="isHiddenError" :closable="isClose">
    </el-alert> -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="手机号注册" name="phone">
        <el-form :label-position="labelPosition" :model="mobileForm" ref="mobileForm" @keyup.enter.native="mobileFormSubmit()" status-icon>
          <el-form-item>
            <el-input v-model="mobileForm.userName" placeholder="用户名" style="font-size: 18px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="mobileForm.password" placeholder="密码" style="font-size: 18px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="mobileForm.mobile" placeholder="手机号"  style="width: 83%; font-size: 18px"></el-input>
            <el-button style="color: dodgerblue" type="text" @click="getCode(mobileForm.mobile)">&nbsp;&nbsp;获取验证码
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-input v-model="mobileForm.code" placeholder="验证码" style="font-size: 18px"></el-input>
          </el-form-item>
          <!-- <el-form-item label="健康小屋名称">
              <el-input v-model="mobileForm.stationName" placeholder="请输入健康小屋名称" ></el-input>
            </el-form-item> -->
          <el-form-item>
            <el-button class="regist-btn-submit" type="primary" @click="mobileFormSubmit()" style="font-size: 18px">注册</el-button>
          </el-form-item>
          <el-form-item>
            <el-button class="regist-btn-submit" type="primary" @click="gotoLogin()" style="font-size: 18px">已有帐号？马上登录</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// import VDistpicker from "v-distpicker";
export default {
  // components: { VDistpicker },
  data() {
    var validateConfirmPassword = (rule, value, callback) => {
      if (this.dataForm.password !== value) {
        callback(new Error("确认密码与密码不一致"));
      } else {
        callback();
      }
    };
    return {
      // isHiddenSuccess: true,
      // isHiddenError: true,
      labelPosition: 'top',
      isClose: false,
      message: "",
      activeName: "phone",
      dataForm: {
        userName: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        province: "",
        city: "",
        area: "",
        region: "",
        inviteCode: "",
        radio: "regist",
      },
      mobileForm: {
        userName: "",
        password: "",
        mobile: "",
        code: "",
      },
      dataRule: {
        userName: [
          { required: true, message: "帐号不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { validator: validateConfirmPassword, trigger: "blur" },
        ],
        captcha: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],
        inviteCode: [
          { required: true, message: "邀请码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    // this.isHiddenSuccess = true;
    // this.isHiddenError = true;
  },
  methods: {
    onChangeProvince(province) {
      this.dataForm.province = province.value;
    },
    onChangeCity(city) {
      this.dataForm.city = city.value;
    },
    onChangeArea(area) {
      this.dataForm.area = area.value;
    },

    // 提交表单--账号注册
    // dataFormSubmit() {
    //   if (this.dataForm.area != "") {
    //     // 只取所选地区的最后一级来送到后端
    //     this.dataForm.region = this.dataForm.area;
    //   } else if (this.dataForm.city != "") {
    //     this.dataForm.region = this.dataForm.city;
    //   } else if (this.dataForm.province != "") {
    //     this.dataForm.region = this.dataForm.province;
    //   }
    //   // console.log(this.dataForm)
    //   this.$refs["dataForm"].validate((valid) => {
    //     if (valid) {
    //       var path = "/sys/" + this.dataForm.radio; // 根据登录角色来提交表单地址
    //       this.$http({
    //         url: this.$http.adornUrl(path),
    //         method: "post",
    //         data: this.$http.adornData({
    //           userName: this.dataForm.userName,
    //           phoneNumber: this.dataForm.phoneNumber,
    //           email: this.dataForm.email,
    //           password: this.dataForm.password,
    //           region: this.dataForm.region,
    //           inviteCode: this.dataForm.inviteCode,
    //         }),
    //       }).then(({ data }) => {
    //         if (data.code === 0) {
    //           console.log("注册成功");
    //           this.isHiddenSuccess = false;
    //           setTimeout(() => {
    //             this.isShowSuccess = true;
    //           }, 2000);
    //           this.$router.replace({ name: "login" });
    //         } else {
    //           console.log("注册失败");
    //           console.log(data);
    //           this.message = data.msg;
    //           this.isHiddenError = false;
    //         }
    //       });
    //     }
    //   });
    // },
    // 提交表单--手机号注册
    mobileFormSubmit() {
      this.$http({
        url: this.$http.adornUrl("/personal/register"),
        // url: this.$http.adornUrl("/personal/app/register4healthStation"),
        method: "post",
        data: this.$http.adornData({
          username: this.mobileForm.userName,
          password: this.mobileForm.password,
          userPhone: this.mobileForm.mobile,
          code: this.mobileForm.code,
          // stationName: this.mobileForm.stationName,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据");
          console.log(data);
          this.$message({
            message: "注册成功！",
            type: "success",
          });
          this.$router.replace({ name: "login" });
        } else {
          console.log(data);
          this.$message({
            message: "注册失败",
            type: "warning",
          });
          this.message = data.msg;
        }
      });
    },
    // 转到登录界面
    gotoLogin() {
      this.$router.replace({ name: "login" });
    },
    // 获取短信验证码
    getCode(phone_number) {
      this.$http({
        url: this.$http.adornUrl("/sms/sendCode"),
        method: "get",
        params: this.$http.adornParams({ phone: phone_number }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据");
          console.log(data);
          this.$message({
            message: "验证码已发至您的手机，请注意查收！",
            type: "success",
          });
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
  },
  mounted() { },
};
</script>

<style lang="scss">
// .brand-info__text {
//   text-align: center;
//   // margin: 0 0 22px 0;
//   font-size: 34px;
//   font-weight: 500px;
//   text-transform: uppercase;
// }
.text-home {
  text-align: center;
  font-size: 30px;
  color: firebrick;
  margin-bottom: 10px;
}

.regist-main {
  position: absolute;
  top: 50%;
  left: 50%;
  // padding: 150px 60px 180px;
  width: 550px;
  transform: translate(-50%, -50%);
  // min-height: 100%;
  // background-color: #fff;
}

.regist-title {
  font-size: 24px;
}

.regist-captcha {
  overflow: hidden;

  >img {
    width: 100%;
    cursor: pointer;
  }
}

.login-btn-submit {
  width: 100%;
  font-size: 18px;
  background-color: #428bca;
  border-color: #428bca;
}

.regist-btn-submit {
  width: 100%;
  font-size: 18px;
  // margin-top: 0px;
  // margin-top: 8px;
  background-color: #428bca;
  border-color: #428bca;
}
</style>
<style lang="scss" scoped>
::v-deep .tabs {
  text-align: center;
  top: 50px;
  left: 50px;
  position: relative;
}

::v-deep .el-tabs {
  color: #000;
  left: 0px;
  top: 0px;
  position: relative;
}

/* 去除灰色横条 */
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/* 设置滑块颜色 */
::v-deep .el-tabs__active-bar {
  background-color: #428bca !important;
}

/* 设置滑块停止位置 */
::v-deep .el-tabs__active-bar.is-top {
  height: 37px;
  width: 125px !important;
  border-radius: 17px;
  top: 0px !important;
  left: -18px !important;
  position: absolute !important;
  z-index: 1;
}

/* 设置当前选中样式 */
::v-deep .el-tabs__item.is-active {
  color: #ffffff !important;
  z-index: 2;
}

/* 设置未被选中样式 */
::v-deep .el-tabs__item {
  padding: 0 20px !important;
  width: 104px;
  box-sizing: border-box;
  display: inline-block;
  position: relative !important;
  color: #000000 !important;
  font-size: 18px;
  z-index: 2;
}
</style>
