<template>
  <div class="site-wrapper site-page--regist">
    <div class="site-content__wrapper">
      <div class="site-content">
        <component v-bind:is='currentRegister'></component>
        <el-button
        v-if="fromMainHome"
            class="back-btn"
            type="primary"
            @click="$router.replace({name:'index'})"
            >退回首页</el-button
            >
      </div>
    </div>
    <main-footer />
  </div>
</template>

<script>
import exampleRegister from '../example_modules/register.vue'
import example1Register from '../example_module1/register.vue'
import stationRegister from '../health_station/register.vue'
import my_healthRegister from '../personal_module/register.vue'
import communityRegister from '../community_station/register.vue'
import doctorRegister from '../doctor_station/register.vue'
import WomenBabyRegister from '../women_baby/register.vue'
import OlderRegister from '../older_station/register.vue'
import HomeOlderRegister from '../home_older/register.vue'
import MainFooter from '../main-footer'
export default {
  data() {
    return {
      fromMainHome: this.$store.state.module.fromMainHome
    };
  },
  components:{
    MainFooter,
    exampleRegister,
    example1Register,
    stationRegister,
    my_healthRegister,
    communityRegister,
    doctorRegister,
    WomenBabyRegister,
    OlderRegister,
    HomeOlderRegister
  },
  computed: {
    // fromMainHome() {
    //   return this.$store.state.module.fromMainHome;
    // },
    moduleName: {
      get() { return this.$store.state.module.moduleName },
      set(val) { this.$store.commit('module/updateModuleName', val) }
    },
    currentRegister: function () {
      if (this.$store.state.module.moduleName === 'example_module') {
        return 'exampleRegister'
      }
      else if (this.$store.state.module.moduleName === 'example_module') {
        return 'example1Register'
      }
      else if (this.$store.state.module.moduleName === 'health_station') {
        return 'stationRegister'
      }
      else if (this.$store.state.module.moduleName === 'personal_module') {
        return 'my_healthRegister'
      }
      else if (this.$store.state.module.moduleName === 'community_station') {
        return 'communityRegister'
      }
      else if (this.$store.state.module.moduleName === 'doctor_station') {
        return 'doctorRegister'
      }
      else if (this.$store.state.module.moduleName === 'women_baby'){
        return 'WomenBabyRegister'
      }
      else if (this.$store.state.module.moduleName === 'older_station'){
        return 'OlderRegister'
      }
      else if (this.$store.state.module.moduleName === 'home_older'){
        return 'HomeOlderRegister'
      }
      else {
        return 'my_healthRegister'
      }
    },
  },
  created() {
    this.fromMainHome = this.$store.state.module.fromMainHome
  },
  methods: {
  },
  mounted() {
    const userType = this.$route.query.userType
    if (userType != null & userType != undefined){
      console.log(userType) // 输出 123
      if (userType == "personal") {
        this.moduleName = "personal_module"
      }
    }
  },
};
</script>

<style lang="scss">
.site-wrapper.site-page--regist {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(152, 175, 196, 0.5);
  overflow: hidden;
  &:before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url(~@/assets/img/regist.jpg);
    background-size: cover;
  }
  .site-content__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: transparent;
  }
  .site-content {
    min-height: 100%;
    padding: 30px 500px 30px 30px;
  }
}
</style>
